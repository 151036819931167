@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

.container {
  max-width: 1200px;
  margin: 15px auto;
  overflow: auto;
  min-height: 100px;
  border: 2px solid steelblue;
  padding:40px;
  border-radius: 5px;
  line-height: 1.8;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header1002{
  /* align-items: center; */
  max-width: 1200px;
  margin: 18px auto;
  overflow: auto;
  padding:1px;
  font-size: 20px;
  cursor: pointer;
  color: #191970;
}

.btn {
  display: inline-block;
  background: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  font-size: 15px;
  font-family: inherit;
}

.btn:focus {
  outline: none;
}

.btn:active {
  transform: scale(0.98);
}

.btn-block {
  display: block;
  width: 100%;
}

.task {
  background: #f4f4f4;
  margin: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.task.reminder {
  border-left: 5px solid green;
}

.task h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-form {
  margin-bottom: 40px;
}

.form-control {
  margin: 20px 0;
}

.form-control label {
  display: block;
}

.form-control input {
  width: 100%;
  height: 40px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}

.form-control-check {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-control-check label {
  flex: 1;
}

.form-control-check input {
  flex: 2;
  height: 20px;
}

footer {
  margin-top: 30px;
  text-align: center;
}

inputCorrect[type=text] {
  background-color: rgb(226, 98, 98);
  padding-left: 40px;
}

inputWrong[type=text] {
  background-color: rgb(108, 180, 108);
  padding-left: 40px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color:grey;
  opacity: 1; /* Firefox */
}