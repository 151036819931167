h1{
    padding: 10px;
    text-align: center;
};


div.cont{
    width:auto;
    border: 10px black;
    margin: 20px;
}

.icontainer {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

 .inputForm{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    box-sizing: border-box;
    height: 200px;
  }

  .subButton{
    display:inline-block;
    padding:0.35em 1.2em;
    border:0.1em solid #FFFFFF;
    margin:0 0.3em 0.3em 0;
    border-radius:0.12em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:#000000;
    text-align:center;
    transition: all 0.2s;
    }
  .subButton:hover{
    color:#ffffff;
    background-color:#000000;
    }
