:root {
    --bg-color: white;
    --typewriterSpeed: 3s;
    --typewriterCharacters: 24;
  }

  .pbody {
    place-content: center;
    text-align: center;
    background: var(--bg-color);
    margin: auto;
    margin-top: 10%;
    min-height: 60vh;
  }

  .user {
    font-size: clamp(1rem, 3vw + 1rem, 4rem);
    position: relative;
    font-family: "Source Code Pro", monospace;
    position: relative;
    width: max-content;
    margin : auto;
  }

  .user::before,
  .user::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .user::before {
    background: var(--bg-color);
    animation: typewriter var(--typewriterSpeed)
      steps(var(--typewriterCharacters)) 1s forwards;
  }

  .user::after {
    width: 0.125em;
    background: black;
    animation: typewriter var(--typewriterSpeed)
        steps(var(--typewriterCharacters)) 1s forwards,
      blink 750ms steps(var(--typewriterCharacters)) infinite;
  }

  .subtitle {
    color: hsl(0 0% 0% / 0.7);
    font-size: 2rem;
    font-weight: 400;
    opacity: 0;
    transform: translateY(3rem);
    animation: fadeInUp 2s ease calc(var(--typewriterSpeed) + 2s) forwards;
  }

  @keyframes typewriter {
    to {
      left: 100%;
    }
  }

  @keyframes blink {
    to {
      background: transparent;
    }
  }

  @keyframes fadeInUp {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  #yt-link {
    position: absolute;
    bottom: 2em;
    width: 100%;
    color: hsl(0 0 0 / 0.7);
  }