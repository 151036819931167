* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.grades,
.experiments {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.instruction {
  text-align: center;
}
h2 {
  text-align: center;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

.fullpage {
  min-height : 65vh;
}
