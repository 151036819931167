.buttonBlue {
    display: inline-block;
    border-radius: 4px;
    background-color: #565656;
    border: none;
    color: white;
    text-align: center;
    font-size: 14px;
    padding: 10px;
    width: 280px;
    transition: all 0.5s;
    cursor: pointer;
  }

  .buttonBlue span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
  }

  .buttonBlue span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
  }

  .buttonBlue:hover span {
    padding-right: 20px;
  }

  .buttonBlue:hover span:after {
    opacity: 1;
    right: 0;
  }
  .centered{
    text-align: center;
    padding-bottom: 10px;
  }

  .logcenter{
    text-align: center;
  }

